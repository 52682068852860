import React from "react";
import { Link } from "gatsby";
import { Navbar, Nav } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai'

const CustomNavbar = ({ data }) => {
  return (
    <>
      <div className="container">
        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" className="mt-1 mt-md-2">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand><Link to="/"><img src={data.picture.publicURL} alt="logo" width="38" /></Link></Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-lg-2 px-4 px-sm-4 px-md-0">
              <Link to="/" className="nav-link" activeClassName="active">Home</Link>
              {/* <Link to="/applications" className="nav-link" activeClassName="active">Applications</Link> */}
              <Link to="/products-services" className="nav-link" activeClassName="active">Products &amp; Services</Link>
              <Link to="/motors" className="nav-link" activeClassName="active">Motors</Link>
              <Link to="/about-us" className="nav-link" activeClassName="active">About Us</Link>
            </Nav>
            <Nav className="ml-md-auto px-3 px-sm-3 px-md-0">
              <button type="button" className="btn btn-outline-success"><a href="mailto:info@gearmission.com">Contact Sales&nbsp;<AiOutlineMail style={{ verticalAlign: 'sub' }} /></a></button>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
}

export default CustomNavbar
