import React, { Component } from 'react';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { animateScroll as scroll } from 'react-scroll';

export default class ScrollUpComponent extends Component {
    _isMounted = false;
    state = {
        show: false
    }
    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('scroll', this.handleScroll, true);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (this._isMounted) {
            if (window.scrollY > 100) {
                this.setState({ show: true })
            } else {
                this.setState({ show: false })
            }
        }
    }

    scrollToTop() {
        scroll.scrollToTop();
    }
    render() {
        return (
            <>
                {this.state.show ?
                    <div className="scrollUp" onClick={this.scrollToTop}>
                        <span className="scrollButton"><MdKeyboardArrowUp /></span>
                    </div>
                    : null
                }
            </>
        )
    }
}