import React from "react";
import { Link } from "gatsby";
import { FaLinkedin, FaPhoneSquareAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";


const CustomFooter = ({ data }) => {
    return (
        <>
            <div className="container-fluid pb-0 mb-0 justify-content-center text-light" id="gFooter">
                <div className="container">
                    <div className="row my-5 justify-content-center">
                        <div className="col-12">
                            <div className="row ">
                                <div className="col-xl-4 col-md-4 col-sm-4 col-12 my-auto mx-auto a">
                                    {/* <h1 className="text-muted mb-md-0 mb-5 bold-text">GearMission</h1> */}
                                    <img src={data.picture.publicURL} className="img-fluid pb-5" />
                                </div>
                                <div className="col-xl-2 d-none d-xl-block"></div>
                                <div className="col-xl-2 col-md-2 col-sm-2 col-12" style={{ borderLeft: 'solid 1px lightGrey' }}>
                                    <h6 className="mb-3 mb-lg-4 bold-text "><b>MENU</b></h6>
                                    <ul className="list-unstyled">
                                        <Link to="/"><li>Home</li></Link>
                                        <Link to="/products-services"><li>Products &amp; Services</li></Link>
                                        <Link to="/motors"><li>Motors</li></Link>
                                        <Link to="/about-us"><li>About Us</li></Link>
                                    </ul>
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-3 col-12">
                                    {data.Customer_service.map((item, index) =>
                                        <div className="row pb-4" key={index}>
                                            <div className="col-12">
                                                <h6 className="text-muted bold-text text-uppercase"><b>{item.title}</b></h6><small><FaPhoneSquareAlt />&nbsp;{item.phone}</small><br /><small><a href={`mailto:${item.email}`}><MdEmail />&nbsp;{item.email}</a></small>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-xl-2 col-md-3 col-sm-3 col-12">
                                    <div className="row pb-4">
                                        <div className="col-12">
                                            <h6 className="text-muted bold-text text-uppercase"><b>Address</b></h6><small><ImLocation2 />&nbsp;{data.Address.street},</small><br /><small>{data.Address.city},</small><br /><small>{data.Address.country}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-xl-8 col-md-4 col-sm-4 col-auto my-md-0 mt-5 order-sm-1 order-3 align-self-end">
                                    <p className="social text-muted mb-0 pb-0 bold-text"> <a className="mx-2" href={data.linkedin} target="_blank"><FaLinkedin style={{ fontSize: '28px' }} /></a></p><small className="rights"><span>&copy;{(new Date().getFullYear())}</span> GearMission All Rights Reserved.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomFooter
