import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Navbar from "./navBar";
import Footer from "./footer";
import ScrollUpComponent from "./scrollUp";

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiNavigation {
          picture {
            publicURL
          }
        }
        strapiFooter {
          picture {
            publicURL
          }
          Customer_service {
            title
            phone
            email
          }
          linkedin
          Address {
            street
            city
            country
          }
        }
      }
    `}
    render={(data) => (
      <>
        <ScrollUpComponent />
        {/* <Seo seo={seo} /> */}
        <header>
          <Navbar data={data.strapiNavigation} />
        </header>
        <main>{children}</main>
        <footer className="footer">
          <Footer data={data.strapiFooter} />
        </footer>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
